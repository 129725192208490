import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function NotFoundPage() {
  return (
    <Wrapper>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/">Go to start</Link>
    </Wrapper>
  );
}

export default NotFoundPage;
